const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

function openSideMenu(menu) {
    // if menu is closed and user is viewing on desktop
    if (!menu.open && window.innerWidth > 768) {
        // enlarge the side menu
        menu.style.transform = 'scale(2)';
        menu.open = true;
        setTimeout(() => {
            if (menu.open) {
                // set menu height to a half
                menu.style.height = `calc(50% - var(--header-height) / 2)`;
            }
        }, 200);
    }
}

function closeSideMenu(menu) {
    // if the user scrolled for at least one quarter, start to animate height to give a smoother feeling
    menu.style.transition = menu.scrollTop > menu.offsetHeight / 4 ? 'height 0.25s ease, transform 150ms ease' : 'transform 150ms ease';
    // back to the original size
    menu.style.transform = 'scale(1)';
    // set menu height to full size
    menu.style.height = `calc(100% - var(--header-height))`;
    menu.open = false;
}

// setting event listeners
const menus = document.getElementsByClassName('side-menu');
for(let i=0; i < menus.length; i++) {
    menus[i].addEventListener('mouseenter', () => openSideMenu(menus[i]));
    menus[i].addEventListener('mousemove', () => openSideMenu(menus[i]));
    menus[i].addEventListener('mouseleave', () => closeSideMenu(menus[i]));
};